import Rails from "rails-ujs";

import toastr from "./toastr_messages";

import $ from "jquery";
import "jquery-mask-plugin/dist/jquery.mask";

function HashJunoCreditCard({
  publicToken,
  isProduction,
  cardNumber,
  holderName,
  securityCode,
  expirationMonth,
  expirationYear,
  success,
  error,
}) {
  var card_data = {
    cardNumber,
    holderName,
    securityCode,
    expirationMonth,
    expirationYear,
  };

  var checkout = new DirectCheckout(publicToken, isProduction);

  checkout.getCardHash(card_data, success, error);
}

function setupFormInitialState() {
  if (!$("#last-card-body").length) {$("#finish-purchase").prop("disabled", true)};
  $("#card_number").mask("0000 0000 0000 0000", { reverse: false });
  $("#card_validity").mask("00/0000", { reverse: false });
  $("#card_cvv").mask("000000", { reverse: false });
  $("#post_code").mask("99999-999");
  $("#holder_cpf").mask("999.999.999-99");

  $("#post_code").keyup(function (e) {
    if ($("#post_code").val().length === 9) {searchZipCode($("#post_code").val())};
  });

  $("#card_cvv").blur(function () {
    generateHashCreditCard();
  });

  $('input[name="chosen_card"]').click(function(){
    var radio = $(this);

    if (radio.val() == "new_card")
    {
      $("#last-card-body").hide();
      $("#new-card-body").show();
      if ($("#card_cvv").val().length < 3) {$("#finish-purchase").prop("disabled", true);};
    } else {
      $("#new-card-body").hide();
      $("#last-card-body").show();
      $("#finish-purchase").prop("disabled", false);
      $("#message").html("");
    }

  });
}

function disable_card_fields(){
  $("#finish-purchase").prop("disabled", true);
  $("#card_number").prop("disabled", true);
  $("#card_validity").prop("disabled", true);
  $("#card_cvv").prop("disabled", true);
}

function clearSpaces(str) {
  return str.replace(/\s/g, "");
}

function searchZipCode(zip_code) {
  Rails.ajax({
    type: "POST",
    url: "/zip_code",
    data: new URLSearchParams({ zip_code }).toString(),
    success: function (response) {
      fillZipCode(response);
    },
    error: function (response) {
      toastr.error(
        "Não foi possível buscar o CEP",
        "Verifique e tente novamente"
      );
      console.error(response);
    },
  });
}

function fillZipCode(data) {
  $("#street").val(data.address);
  $("#city").val(data.city);
  $("#state").val(data.state);
  $("#number").focus();
}

function generateHashCreditCard() {
  var publicToken = $("#public_token").val();
  var environment = $("#environment").val();
  var holderName = $("#holder_name").val();
  var cardNumber = clearSpaces($("#card_number").val());
  var cardValidate = $("#card_validity").val().split("/");
  var securityCode = $("#card_cvv").val();
  var expirationMonth = cardValidate[0];
  var expirationYear = cardValidate[1];

  $("#message").html("Processando, aguarde...");

  $("#finish-purchase").prop("disabled", true);

  HashJunoCreditCard({
    publicToken,
    cardNumber,
    holderName,
    securityCode,
    expirationMonth,
    expirationYear,
    isProduction: environment === "production",
    success: function (cardHash) {
      var card_last_numbers = cardNumber.slice(-4);

      $("#card_token").val(cardHash);
      $("#card_last_numbers").val(card_last_numbers);

      $("#finish-purchase").prop("disabled", false);
      $("#message").html("");
    },
    error: function (error) {
      $("#message").html(
        "Parece que os dados de seu cartão não conferem. Verifique e tente novamente."
      );
      $("#btn-submit-donation").prop("disabled", true);
      $("#card_cvv").val("");
    },
  });
}

$(document).on('submit','form.juno-credit-card-form',function(){
  disable_card_fields();
});

document.addEventListener("turbolinks:load", function () {
  if (!!$("form.juno-credit-card-form")) setupFormInitialState();
});
