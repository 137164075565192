// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("channels");

import "src/shared/bootstrap_theme";
import "src/stylesheets/site.scss";
import "src/stylesheets/home/home.scss";
import "src/stylesheets/user_area/courses.scss";
import "src/shared/user_avatar_upload";
import "src/shared/user_avatar_site_upload";
import "src/shared/help_request_course_select";
import "src/shared/toastr_messages";
import "src/shared/checkout_form";
import "src/stylesheets/site/packages.scss";
import "src/stylesheets/site/courses.scss";
import "src/shared/course_filters";