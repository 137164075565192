import React from "react";
import ReactDOM from "react-dom";

import ImageUploader from "../../components/ImageUploader/ImageUploader.js";

function setupUserAvatarUpload() {
  const place = document.querySelector("#user_avatar_placeholder");

  if (!place) return;
  const template = place.querySelector("template");

  const props = template ? JSON.parse(template.innerHTML) : {};
  ReactDOM.render(
    <ImageUploader
      formInputName="user[avatar]"
      label="Avatar"
      placeholder=""
      {...props}
    />,
    place
  );
}

document.addEventListener("turbolinks:load", function () {
  setupUserAvatarUpload();
});
