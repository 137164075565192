import $ from "jquery";
import "select2/dist/css/select2.css";
import "select2-theme-bootstrap4/dist/select2-bootstrap.css";
import "select2/dist/js/select2.js";
import I18n from '../../i18n-js/index.js.erb'

document.addEventListener("turbolinks:load", function () {

    $('#tag_ids').select2({
        theme: "bootstrap",
        placeholder: I18n.t("user_area.courses.filters.tags"),
        allowClear: true
    });

    $('#competence_ids').select2({
        theme: "bootstrap",
        placeholder: I18n.t("user_area.courses.filters.competences"),
        allowClear: true
    });

    $('#knowledge_area_ids').select2({
        theme: "bootstrap",
        placeholder: I18n.t("user_area.courses.filters.knowledge_areas"),
        allowClear: true
    });

    $('#job_title_ids').select2({
        theme: "bootstrap",
        placeholder: I18n.t("user_area.courses.filters.job_titles"),
        allowClear: true
    });

    $('#operation_ids').select2({
        theme: "bootstrap",
        placeholder: I18n.t("user_area.courses.filters.operations"),
        allowClear: true
    });

    $('#organizational_area_ids').select2({
        theme: "bootstrap",
        placeholder: I18n.t("user_area.courses.filters.organizational_areas"),
        allowClear: true
    });

    $('#course_progress').select2({
        theme: "bootstrap",
        placeholder: I18n.t("user_area.courses.filters.course_progress"),
        allowClear: true

    });

    $('.select2-container--bootstrap').css('width', '100%');
    $('.select2-search__field').css('width', '100%');

});


document.addEventListener("turbolinks:before-cache", function() {
    $('#tag_ids').select2('destroy');
    $('#competence_ids').select2('destroy');
    $('#knowledge_area_ids').select2('destroy');
    $('#job_title_ids').select2('destroy');
    $('#operation_ids').select2('destroy');
    $('#organizational_area_ids').select2('destroy');
    $('#course_progress').select2('destroy');
});
