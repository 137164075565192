import React from "react";
import ReactDOM from "react-dom";

import AvatarUploader from "../../components/AvatarUploader/AvatarUploader.js";

function setupAvatarUpload() {
  const place = document.querySelector("#user_avatar_site_placeholder");

  if (!place) return;
  const template = place.querySelector("template");

  const props = template ? JSON.parse(template.innerHTML) : {};
  ReactDOM.render(
      <AvatarUploader
          formInputName="user[avatar]"
          placeholder=""
          height="150px"
          width="150px"
          {...props}
      />,
      place
  );
}

document.addEventListener("turbolinks:load", function () {
  setupAvatarUpload();
});
