import $ from "jquery";
import "select2/dist/css/select2.css";
import "select2-theme-bootstrap4/dist/select2-bootstrap.css";
import "select2/dist/js/select2.js";


document.addEventListener("turbolinks:load", function () {

    $('label[for="help_request_course_id"]').hide();

    $("#help_request_subject").change(function (e) {

        $('label[for="help_request_course_label"]').text('Trilha');

        $('#help_request_course_id').removeAttr('required');

        if ($('#help_request_subject').val() == 'content') {
            $("#help_request_course_id").attr("required", "true");
            $('label[for="help_request_course_label"]').text('Trilha *');
        }
    });

});


document.addEventListener("turbolinks:before-cache", function() {
});
